<template>
  <div>
    <b-card
      v-if="userData"
      class="blog-edit-wrapper"
    >
      <validation-observer
        ref="userRules"
        #default="{ handleSubmit }"
      >
        <!-- form -->
        <b-form>
          <b-row>

            <b-col cols="12">
              <div class="d-flex align-items-center mb-2">
                <feather-icon
                  icon="UserIcon"
                  size="16"
                />
                <h3 class="mb-0 font-weight-normal">
                  User Information
                </h3>
              </div>
            </b-col>

            <b-col sm="12">
              <b-row
                class="mb-2"
              >
                <b-col
                  sm="4"
                >
                  <b-form-group
                    label="Role"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Role"
                      rules="required"
                    >
                      <b-form-select
                        v-model="userData.role_name"
                        :options="rolesList"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col sm="4">
              <b-form-group
                label="Email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="userData.email"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="4">
              <b-form-group
                label="First Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="userData.first_name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="4">
              <b-form-group
                label="Last Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="userData.last_name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="4">
              <b-form-group
                label="Phone Number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone Number"
                  rules="required"
                >
                  <b-form-input
                    v-model="userData.phone"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="4">
              <b-form-group
                label="Active"
              >
                <b-form-checkbox
                  v-model="isActive"
                  class="custom-control-primary"
                >
                  true
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col sm="12">
              <b-row
                class="mb-2"
              >
                <b-col
                  sm="4"
                >
                  <b-form-group
                    label="Password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required|min:6"
                    >
                      <b-form-input
                        v-model="userData.password"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col sm="12">
              <div
                v-if="validationErrors.length"
                class="form-group pt-1"
              >
                <p
                  v-for="(validationError, index) in validationErrors"
                  :key="index"
                  class="text-center text-danger"
                >
                  {{ validationError }}
                </p>
              </div>
            </b-col>

            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                variant="primary"
                class="mr-1"
                @click="handleSubmit(createUserProfile)"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :to="{ name: 'admin-users'}"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!--/ form -->
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, min } from '@validations'
import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from '@/views/admin/users/usersStoreModule'
import useUserAdd from '@/views/admin/users/user-add/useUserAdd'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        role_id: null,
        role_name: null,
        password: null,
        extra_user_info: {
          gender: null,
        },
      },
      isActive: false,
      rolesList: [{ value: null }],
      required,
      email,
      min,
      validationErrors: [],
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-users'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      createUser,
      createAdmin,
    } = useUserAdd()

    return {
      createUser,
      createAdmin,
    }
  },
  async created() {
    this.rolesList = [
      { value: null, text: 'Select Role' },
      { value: 'admin', text: 'Admin' },
      { value: 'tag_manager', text: 'Tag Manager' },
    ]
    // this.rolesList = await this.$store.dispatch('app-users/fetchRolesList')
    //   .then(response => response.data.data.reduce((arr, item) => {
    //     if (item.name === 'admin') {
    //       arr.push({ value: item.id, text: item.display_name })
    //     }
    //     return arr
    //   }, [{ value: null, text: 'Select Role' }]))
  },
  methods: {
    async createUserProfile() {
      this.validationErrors = []

      if (['admin', 'tag_manager'].includes(this.userData.role_name)) {
        this.userData.is_active = this.isActive
        await this.createAdmin(this.userData)
          .then(response => {
            if (response.status === 422) {
              const validationErrors = Object.values(response.data.errors)
              this.validationErrors = validationErrors.flat()
            } else {
              this.$router.push({ name: 'admin-users' })
            }
          })
      } else {
        await this.createUser(this.userData).then(() => {
          this.$router.push({ name: 'users' })
        })
      }
    },
  },
}
</script>

<style lang="scss">

</style>
