import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUserAdd() {
  // Use toast
  const toast = useToast()

  const createUser = queryParams => axios
    .post('/auth/users', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New User was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      if (error.response.data.errors?.email) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.errors.email[0],
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error creating user item',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    })
  const createAdmin = queryParams => axios
    .post('/auth/admins', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Admin was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error adding Admin',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    createUser,
    createAdmin,
  }
}
